import React, {useEffect, useState} from 'react';
import {activationUser} from '../../components/Auth/Utils';
import withLocation from '../../components/shared/withLocation';
import Layout from "../../components/shared/layout";
import SEO from "../../components/SEO/SEO";
import Img from "gatsby-image";
import ButtonLink from "../../components/shared/ButtonLink";
import {graphql} from "gatsby";
import styled from "@emotion/styled";
import {wrapper, breakpoints} from "../../utils/style";

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  max-width: 1244px;
  p {
    margin-bottom: 1rem;
  }
  .load-activating {
    justify-content: flex-end;
  }
    
  @media (max-width: ${breakpoints.md}) {
    .load-activating {
      justify-content: flex-start;
    }
  }
`;

const ActivationUserTemplate = ({pageContext, data, search, location, navigate }) => {

  const homePageUrl = process.env.URL;
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [activationError, setActivationError] = useState(false);

  if(search && (search.action === 'activation') && search.email && search.code) {
    activationUser('', search, setRedirectToHome, setActivationError)
  }

  useEffect(() => {
    if(redirectToHome){
      navigate(homePageUrl);
    }
    if(search && (search.action !== 'activation')) {
      navigate(homePageUrl);
    }
  }, [redirectToHome]);

  return (
    <Layout>
      <SEO title="Account Activation" description="Info on your Gclub account activation"/>
      <Wrapper>
        {activationError ? (
          <div className="flex">
            <div className="col-2">
              {data.missing.childImageSharp.fluid ?
                <Img alt="GCLUB Activation" fluid={data.missing.childImageSharp.fluid}/>
                : "GCLUB Activation Progress"
              }
            </div>
            <div className="col-2">
              <h1>Activation Notification:</h1>
              <p>ขออภัย ลิ้งก์เปิดใช้งานไม่ถูกต้อง หรือ มีการเปิดใช้งานไปแล้วก่อนหน้า</p>
              <ButtonLink to="/" text="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก" ariaText="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"/>
            </div>
          </div>
        ) : (
          <div className="flex load-activating">
            <div className="col-2">
              <h1>Activating account...</h1>
            </div>
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};
export default withLocation(ActivationUserTemplate);

// Set here the ID of the home page.
export const pageQuery = graphql`
    query {
        missing: file(relativePath: { eq: "logo-gclub-casino.png" }) {
            childImageSharp {
                fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
